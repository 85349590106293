export const listingTypeEnum = {
  GENERAL_BUSINESS: 'general-business',
  PARTNER: 'partner_proflie',
  PROMO: 'new_deal',
  EVENT: 'event_info',
  ITALIAN_PASS: 'italian-pass',
}
export const listingFieldEnum = {
  PARTNER_LISTING_ID: 'partnerListingID',
  PARTNER_LISTING_TITLE: 'partnerListingTitle',
  CATEGORY: 'category',
  TAGS: 'tags',
  REGION: 'region',
  LOCATION_SUMMARY: 'location-summary',
  START_DATE_TIME: 'start-date-time',
  // these are opening hours not appointment times
  APPOINTMENT_TIME_MONDAY: 'appointment-time-monday',
  APPOINTMENT_TIME_TUESDAY: 'appointment-time-tuesday',
  APPOINTMENT_TIME_WEDNESDAY: 'appointment-time-wednesday',
  APPOINTMENT_TIME_THURSDAY: 'appointment-time-thursday',
  APPOINTMENT_TIME_FRIDAY: 'appointment-time-friday',
  APPOINTMENT_TIME_SATURDAY: 'appointment-time-saturday',
  APPOINTMENT_TIME_SUNDAY: 'appointment-time-sunday',
  BOOKING_URL: 'booking-url',
  WEBSITE:"website",
  PHONE_NUMBER:"phone_number",
  EMAIL:"email",
  PRICE_RANGE:"price-range",
  TAGLINE:"tagline",
  NON_MEMBER_PRICE_DOLLARS:"non-member-price-dollars",
  DEALS_TITLE:"deals-title",
  DEALS:"deals",
  // italian pass
  VALUE_DOLLARS:"value-dollars",
  VALIDITY_DAYS:"validity-days",

  // END_DATE_TIME: 'end-date-time',
}

export const categoryOptionEnum = {
  SERVICE: 'bell-concierge',
  SHOP: 'shop',
  GYM_AND_FITNESS: 'dumbbell',
  RESTAURANTS: 'utensils',
  NIGHTLIFE: 'star',
  BEAUTY: 'user-check',
  ART_AND_HISTORY: 'palette',
  OUTDOOR_ACTIVITIES: 'person-hiking',
  CINEMAS: 'film',
  TECHNOLOGY: 'microchip',
}

export const tagsOptionEnum = {
  ACCEPT_CREDIT_CARDS: 'credit-card',
  STREET_PARKING: 'square-parking',
  BIKE_PARKING: 'bicycle',
  UBER_EATS: 'uber',
  AFTERPAY: 'money-bill',
  WIFI: 'wifi',
  SMOKING: 'smoking',
  HEATED_SEATS: 'temperature-arrow-up',
}
