import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/UserDashboardPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/UserDashboardPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/UserDashboardPage/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchTransactionsError: null,
  transactions: [],
  deals: [],
};

export default function userDashboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchTransactionsError: null };
    case FETCH_TRANSACTIONS_SUCCESS: {
      const { transactions, deals } = payload;
      return {
        ...state,
        fetchInProgress: false,
        transactions,
        deals,
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchTransactionsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});
const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => (dispatch, _getState, _sdk) => {
  dispatch(fetchTransactionsRequest());

  return fetch('/api/get-customer-transactions', {credentials: 'include'})
    .then(response => {
      return response.json();
    })
    .then(response => {
      dispatch(fetchTransactionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });
};

export const reserve = (deal, item) => (dispatch, _getState, _sdk) => {
  dispatch(fetchTransactionsRequest());
  return fetch('/api/reserve', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ deal, item }),
    credentials: 'include',
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      dispatch(fetchTransactionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });
}

export const cancelReservation = tx => (dispatch, _getState, _sdk) => {
  dispatch(fetchTransactionsRequest());
  return fetch('/api/cancel-reservation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tx }),
    credentials: 'include',
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      dispatch(fetchTransactionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });
}
